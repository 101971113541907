<template>
  <div class="as_modal">
    <div class="as_modal_content" :class="modal_width_class">
      <span class="as_modal_title">{{ title }}</span>
      <slot></slot>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    size: {
      type: String,
      default: 'md',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    modal_width_class() {
      return `modal_size_${this.size}`;
    },
  },
};
</script>
<style type="text/css" scoped>
.as_modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.as_modal_content {
  background-color: white;
  height: auto;
  padding: 10px;
  width: 300px;
}
.modal_size_sm {
  width: 300px;
}
.modal_size_md {
  width: 300px;
}
.modal_size_lg {
  width: 300px;
}
@media (min-width: 601px) {
  .modal_size_sm {
    width: 300px;
  }
  .modal_size_md {
    width: 600px;
  }
  .modal_size_lg {
    width: 900px;
  }
}
.as_modal_title {
  display: block;
  text-align: center;
  background-color: var(--standard-color);
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
