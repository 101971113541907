<template>
  <div>
    <div class="form-row">
      <div class="col-sm-6 col-12">
        <div class="form-group">
          <label>Nome:</label>
          <input type="text" class="form-control" v-model="filter.name" />
        </div>
      </div>
      <div class="col-sm-6 col-12">
        <div class="form-group">
          <label>Email:</label>
          <input type="email" class="form-control" v-model="filter.email" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="offset-sm-6 col-sm-6 col-12">
        <div class="form-group">
          <label>Status:</label>
          <select class="form-control" v-model="filter.status">
            <option value="">Todos</option>
            <option value="active">Ativos</option>
            <option value="inactive">Inativos</option>
          </select>
        </div>
      </div>
    </div>

    <filter-buttons @restart_filter="restart_filter" @cancel="cancel" @filter="filter_data" />
  </div>
</template>
<script type="text/javascript">
import FilterButtons from '@/theme/buttons/FilterButtons.vue';
import functions from '@/functions';

export default {
  name: 'usersFilter',
  props: {
    filterParm: {
      type: Object,
    },
  },
  components: {
    FilterButtons,
  },
  data() {
    return {
      filter: {
        name: '',
        email: '',
        status: '',
      },
    };
  },
  methods: {
    setInitialFilter() {
      this.resetFilterObject();
      if (this.filterParm) this.getFilterFromParm();
    },
    getFilterFromParm() {
      const filterKeys = Object.keys(this.filterParm);
      filterKeys.forEach((key) => {
        this.filter[key] = '';
      });
    },
    resetFilterObject() {
      this.filter = functions.cleanObjectProperties(this.filter);
    },
    restart_filter() {
      this.resetFilterObject();
      this.filter_data();
    },
    filter_data() {
      this.$emit('filter', this.filter);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    'filterParm.name': {
      handler() {
        this.filter.name = this.filterParm.name;
      },
    },
    'filterParm.email': {
      handler() {
        this.filter.email = this.filterParm.email;
      },
    },
    'filterParm.status': {
      handler() {
        this.filter.status = this.filterParm.status;
      },
    },
  },
  mounted() {
    this.setInitialFilter();
  },
};
</script>
