<template>
  <div class="pad-10">
    <spinner :loading="processing" />
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label>Nome:</label>
          <input type="text" v-model="user.name" class="form-control" />
          <small>{{ userErrors.name }}</small>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label>Email:</label>
          <input type="email" v-model="user.email" class="form-control" />
          <small>{{ userErrors.email }}</small>
        </div>
      </div>
    </div>
    <div class="form-row" v-if="user_id == 'new'">
      <div class="col">
        <div class="form-group">
          <label>Senha:</label>
          <password-input v-model="user.password" />
          <small>{{ userErrors.password }}</small>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Confirme a senha:</label>
          <password-input v-model="user.password_confirmation" />
          <small>{{ userErrors.password_confirmation }}</small>
        </div>
      </div>
    </div>
    <process-cancel-buttons @process="process_data" @cancel="cancel" />
  </div>
</template>
<script type="text/javascript">
import { mapActions } from 'vuex';
import PasswordInput from '@/features/passwordInput/PasswordInput.vue';
import ProcessCancelButtons from '@/theme/buttons/ProcessCancelButtons.vue';
import Spinner from '@/features/Spinner/Spinner.vue';

export default {
  components: {
    PasswordInput,
    ProcessCancelButtons,
    Spinner,
  },
  props: {
    user_id: {
      type: [String, Number],
      default: 'new',
    },
  },
  data() {
    return {
      processing: false,
      user: {
        id: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      userErrors: {
        name: '',
        email: '',
        password: '',
      },
      actions: {
        prepare_form: {},
        process_form: {},
      },
    };
  },
  methods: {
    ...mapActions('managers', [
      'ActionInsertUser',
      'actionListUsers',
      'ActionGetUser',
      'ActionUpdateUser',
    ]),
    reset_form() {
      Object.keys(this.user).forEach((key) => {
        this.user[key] = '';
      });
    },
    reset_form_errors() {
      Object.keys(this.userErrors).forEach((key) => {
        this.userErrors[key] = '';
      });
    },
    async load_user() {
      try {
        this.processing = true;
        this.user = await this.ActionGetUser({ id: this.user_id });
      } catch (error) {
        console.log(error);
      } finally {
        this.processing = false;
      }
    },
    verify_action_type() {
      if (this.user_id === 'new') {
        this.actions.prepare_form = () => this.reset_form();
        this.actions.process_form = () => this.insert_user();
      } else {
        this.actions.prepare_form = () => this.load_user();
        this.actions.process_form = () => this.update_user();
      }
    },
    process_data() {
      this.actions.process_form();
    },
    cancel() {
      this.$emit('cancel');
    },
    async insert_user() {
      try {
        this.processing = true;
        this.reset_form_errors();
        await this.ActionInsertUser(this.user);
        await this.actionListUsers();
        this.cancel();
      } catch (error) {
        this.setErrorMessages(error);
      } finally {
        this.processing = false;
      }
    },
    async update_user() {
      try {
        this.processing = true;
        this.reset_form_errors();
        await this.ActionUpdateUser({ id: this.user_id, data: this.user });
        await this.actionListUsers();
        this.cancel();
      } catch (error) {
        this.setErrorMessages(error);
      } finally {
        this.processing = false;
      }
    },
    setErrorMessages(resonseError) {
      const { errors } = resonseError.response.data;
      Object.keys(errors).forEach((key) => {
        const [message] = errors[key];
        this.userErrors[key] = message;
      });
    },
  },
  watch: {
    user_id() {
      this.verify_action_type();
      this.actions.prepare_form();
    },
  },
  mounted() {
    this.verify_action_type();
    this.actions.prepare_form();
  },
};
</script>
<style type="text/css"></style>
