export default {
  methods: {
    showHttpErrorsOndialogbox(error) {
      const { errors } = error.response.data;
      const messages = Object.keys(errors).map((errorKey) => errors[errorKey][0]);
      this.$dialogbox.error(messages.join('\n'));
    },
    putHttpErrorMessagesOnform(errorsObject) {
      const keys = Object.keys(errorsObject);
      const formattedErrorsObject = {};
      keys.foreach((key) => {
        const [message] = errorsObject[key];
        formattedErrorsObject[key] = message;
      });
      return formattedErrorsObject;
    },
  },
};
